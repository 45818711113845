/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prací prostředek KOFL"}>
        <Column className="css-tb9ccs --full --parallax pb--60 pt--60" name={"9khifxuqih9"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/f75803eeaa7547a8b1890d14b080adf4_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/f75803eeaa7547a8b1890d14b080adf4_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/f75803eeaa7547a8b1890d14b080adf4_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/f75803eeaa7547a8b1890d14b080adf4_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/f75803eeaa7547a8b1890d14b080adf4_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/f75803eeaa7547a8b1890d14b080adf4_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/f75803eeaa7547a8b1890d14b080adf4_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/f75803eeaa7547a8b1890d14b080adf4_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow2 fs--102 title-box--invert" content={"<span style=\"color: var(--color-blend--85);\">KOFL washing</span>"}>
              </Title>

              <Text className="text-box text-box--invert" style={{"maxWidth":650}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"aj3mef3zn3i"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/10603f649776442db06c330d121ee987_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/10603f649776442db06c330d121ee987_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/10603f649776442db06c330d121ee987_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/10603f649776442db06c330d121ee987_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/10603f649776442db06c330d121ee987_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--26" style={{"maxWidth":961}} content={"<span style=\"font-weight: bold;\">KOFL prací gel univerzál 10,5 L</span>&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Jedinečný a silný prací gel vyvinutý pro západní trhy, který&nbsp;šetří energii a životní prostředí s dlouhotrvající vůní.<br>Navíc dlouhotrvající ochranný <span style=\"font-weight: bold;\">faktor King of Laundry</span>&nbsp;pro zářivé barvy. Toto balení vystačí až na 300 pracích dávek. Určen pro bílé a stálobarevné prádlo.&nbsp;<span style=\"font-weight: bold;\">Není vhodný ho použit na sportovní prádlo.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/01444a6e95f842b5a4c92a586415a525_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/01444a6e95f842b5a4c92a586415a525_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/01444a6e95f842b5a4c92a586415a525_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/01444a6e95f842b5a4c92a586415a525_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/01444a6e95f842b5a4c92a586415a525_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--26" content={"<span style=\"font-weight: bold;\">KOFL prací gel color 10,5 L </span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Jedinečný a silný prací gel vyvinutý pro západní trhy, který šetří energii a životní prostředí s dlouhotrvající vůní.\nNavíc dlouhotrvající ochranný faktor <span style=\"font-weight: bold;\">King of Laundry</span> pro zářivé barvy. Toto balení vystačí až na 300 pracích dávek.&nbsp;Určen pro barevné prádlo.&nbsp;<span style=\"font-weight: bold;\">Není vhodný ho použit na sportovní prádlo.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/699574895f0748f48ef5a143645621fc_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/699574895f0748f48ef5a143645621fc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/699574895f0748f48ef5a143645621fc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/699574895f0748f48ef5a143645621fc_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box fs--26" content={"<span style=\"font-weight: bold;\">KOFL prací gel color 10,5 L</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Jedinečná aviváž pro změkčení a dlouhotrvající vůni vašeho prádla. <br>Vyvinutá pro západní trhy, která šetří energii a životní prostředí. Navíc dlouhotrvající ochranný faktor King of Laundry pro měkkost a vůni.. Toto balení vystačí až na 300&nbsp; dávek. <span style=\"font-weight: bold;\">Není vhodný ho použit na sportovní prádlo.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"nc3du7ylup"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"9cwdwcw2k6m"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5a0eecacd2424abf81c900821b205807.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"Vysoký poměr koncentrace: \n\"Už žádné plýtvání a nekonečné dávkování! S naší vysokou koncentrací KOFL washing pracích prostředků dosáhnete maximální účinnosti již při použití minimálního množství. Připravte se na revoluci v praní, kde jedna láhev vydrží mnohem déle, než jste zvyklí, a vaše prádlo bude dokonale čisté již po prvním praní. Ušetříte nejen peníze, ale i čas a prostor ve vaší prádelně.\""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5a0eecacd2424abf81c900821b205807.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"Krásné vůně:\n\"Probudí vaše smysly a obleče vaše prádlo do dlouhotrvající, svěží vůně! S KOFL washing se každý kus oblečení stane nositelem nebesky čisté a svěží aury, která vás bude provázet po celý den. Zanechte po sobě nezapomenutelný dojem s vůní, která je tak přitažlivá, že se stane vaším tajným módním doplňkem.\""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}